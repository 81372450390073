// 数据流文件处理，并下载
function blobDownload(data, fileName, type = 'application/zip') {
    let blob = new Blob([data], {
        type
    })
    if (window.navigator.msSaveBlob) {
        // ie
        window.navigator.msSaveOrOpenBlob(blob, fileName)
        return
    }

    let elink = document.createElement('a')
    elink.style.display = 'none'
    elink.href = window.URL.createObjectURL(blob)
    elink.download = fileName
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
}

export {
    blobDownload
}